import React from "react";
import {
  mapping,
  DropDownListButton,
  FormatDate,
  taskManagementStyle,
} from "@aim-mf/styleguide";
import { TaskCard } from "./taskCard";

// Properties
//// Title
//// Filter list 1
//// Filter list 2
//// InboxData
//// PendingData

class TaskPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let taskDataShown = this.props.taskDataShown.filter((taskItem) => {
      return taskItem.demo_meta_data != null;
    });
    taskDataShown = taskDataShown.filter((taskItem) => {
      return (
        JSON.parse(taskItem.demo_meta_data).usage === "case-management-demo"
      );
    });
    taskDataShown = taskDataShown.map((item) => {
      item.meta_date = JSON.parse(item.demo_meta_data);
      return item;
    });
    // console.log(taskDataShown)
    taskDataShown =
      this.props.filterValue1 === "Alert"
        ? taskDataShown.filter((task) => task.type.includes("alert"))
        : this.props.filterValue1 === "Task"
        ? taskDataShown.filter((task) => task.type.includes("task"))
        : taskDataShown;
    taskDataShown =
      this.props.filterValue2 === "Creation Date"
        ? taskDataShown.sort((a, b) =>
            FormatDate(a.date, "YYYYMMDD") > FormatDate(b.date, "YYYYMMDD")
              ? 1
              : -1
          )
        : taskDataShown.sort((a, b) =>
            FormatDate(a.dueDate, "YYYYMMDD") >
            FormatDate(b.dueDate, "YYYYMMDD")
              ? 1
              : -1
          );
    return (
      <div
        style={{
          ...taskManagementStyle.PanelStyle,
          width: this.props.viewHistory ? "96.5rem" : "47.75rem",
        }}
      >
        {/* HEADER */}
        <div style={{ flexDirection: "column" }}>
          <div
            style={{
              ...taskManagementStyle.SectionStyle,
              width: this.props.viewHistory ? "94.5rem" : "45.75rem",
            }}
          >
            <div style={{ ...mapping["heading/h5/lightleft"], float: "left" }}>
              {this.props.title}
            </div>
            <div style={taskManagementStyle.taskCountStyle}>
              {taskDataShown.length}
            </div>
          </div>
          {/* SEARCH & FILTER */}
          <div
            style={{
              ...taskManagementStyle.SectionStyle,
              marginBottom: "1.5rem",
              width: this.props.viewHistory ? "94.5rem" : "45.75rem",
            }}
          >
            <div style={{ float: "left" }}>
              <SearchBar
                onCustomChange={this.props.handleSearch}
                width={this.props.viewHistory ? "74.75rem" : "26rem"}
              />
            </div>
            <div style={{ float: "left", marginLeft: "1.25rem" }}>
              <DropDownListButton
                value={this.props.filterValue1}
                data={this.props.filterList1}
                onChange={this.props.handleFilter1}
              />
            </div>
            <div style={{ float: "left", marginLeft: "1rem" }}>
              <DropDownListButton
                value={this.props.filterValue2}
                data={this.props.filterList2}
                onChange={this.props.handleFilter2}
              />
            </div>
          </div>
        </div>
        {/* TASK CARDS */}

        <CheckTaskCards
          taskDataShown={taskDataShown}
          taskData={this.props.taskData}
        />

        <div
          className={"scrollbar"}
          style={{
            ...taskManagementStyle.taskCardListWrapper,
            width: this.props.viewHistory ? "96rem" : "46.25rem",
          }}
        >
          {taskDataShown
            .sort((a, b) => {
              return a.id > b.id ? 1 : -1;
            })
            .map((task, index) => {
              return (
                <div
                  style={{
                    marginLeft:
                      index % 2 !== 0 && this.props.viewHistory
                        ? "48.75rem"
                        : "0rem",
                  }}
                >
                  <TaskCard
                    type={this.props.title}
                    userList={this.props.userList}
                    taskCardData={task}
                    handleViewTask={this.props.handleViewTask}
                    viewHistory={this.props.viewHistory}
                    // handleViewTaskClose={this.props.handleViewTaskClose}
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
const SearchBar = (props) => {
  return (
    <input
      name={(event) => {
        event.target.value;
      }}
      placeholder={"Search by keywords"}
      style={Object.assign({}, mapping["forms/input-default"], {
        ...taskManagementStyle.InputFieldStyle,
        width: props.width ? props.width : "26rem",
      })}
      onChange={(event) => {
        props.onCustomChange(event.target.value);
      }}
    />
  );
};
const CheckTaskCards = (props) => {
  let taskDataShown = props.taskDataShown;
  let taskData = props.taskData;
  if (taskData.length === 0) {
    return (
      <div
        style={{
          ...mapping["paragraph/default/lightcenter"],
          marginTop: "21.25rem",
        }}
      >
        You have no new tasks
      </div>
    );
  } else if (taskData.length !== 0 && taskDataShown.length === 0) {
    return (
      <div
        style={{
          ...mapping["paragraph/default/lightcenter"],
          marginTop: "21.25rem",
        }}
      >
        No results found
      </div>
    );
  }
  {
    return <div> </div>;
  }
};

export { TaskPanel };
